import React from 'react'

import useProgressiveImg from './hooks/useProgressiveImage'

const ProgressiveImage = ({ lowQuality, alt, highQuality }) => {
	const [src, { blur }] = useProgressiveImg(lowQuality, highQuality)

	return (
		<img
			src={src}
			alt={alt}
			style={{
				filter: blur ? 'blur(5px)' : 'none',
				transform: blur ? 'scale(1.03)' : 'none',
				transition: blur ? 'none' : 'filter 80ms ease-out',
			}}
		/>
	)
}

export default ProgressiveImage
