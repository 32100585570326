import React from 'react'
import { graphql } from 'gatsby'
import App from '../components/App'

const IndexPage = ({ data }) => {
	return <App data={data} />
}

export const query = graphql`
	query HomePageQuery {
		content: allAirtable(filter: { table: { eq: "Content" } }) {
			nodes {
				data {
					ID
					Tekst
				}
			}
		}
		links: allAirtable(filter: { table: { eq: "Contactgegevens" } }) {
			nodes {
				data {
					Platform
					URL
				}
			}
		}
		demos: allAirtable(filter: { table: { eq: "Demo-opnames" } }) {
			nodes {
				data {
					Bandcamp
				}
			}
		}
		videos: allAirtable(filter: { table: { eq: "YouTube-videos" } }) {
			nodes {
				data {
					URL
				}
			}
		}
	}
`
export default IndexPage
