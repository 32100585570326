import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { CSSTransition } from 'react-transition-group'
import { throttle } from 'lodash'

import ScrollLink from './ScrollLink'

const MobileMenu = () => {
	const [isVisible, setVisible] = React.useState(false)
	const [isOpen, setOpen] = React.useState(false)

	const toggleOpen = () => {
		setOpen(!isOpen)
	}

	const scrollToTop = (event) => {
		event.preventDefault()
		toggleOpen()
		scroll.scrollToTop({
			duration: 375,
		})
	}

	const handleScroll = React.useCallback(() => {
		const shouldBeVisible = window.scrollY > 750
		setVisible(shouldBeVisible)
	}, [])

	React.useEffect(() => {
		handleScroll()

		const throttledHandleScroll = throttle(handleScroll, 250, {
			trailing: false,
		})
		document.addEventListener('scroll', throttledHandleScroll)

		return () => {
			document.removeEventListener('scroll', throttledHandleScroll)
		}
	}, [handleScroll])

	return (
		<div
			className={
				'mobile-menu ' + (isVisible ? 'visible' : '') + (isOpen ? ' open' : '')
			}
		>
			<div className={'mobile-menu-button'}>
				<button aria-label='Open het mobiele menu' onClick={toggleOpen}>
					<div className={'hamburger-icon'}></div>
				</button>
			</div>

			<CSSTransition in={isOpen} timeout={200} classNames='animation'>
				<div className={'menu-container'}>
					<button
						aria-label='Sluit het mobiele menu'
						className={'close-button'}
						onClick={toggleOpen}
					>
						<i className={'icon icon-cancel'}></i>
					</button>

					<ul className={'menu'}>
						<li>
							<a onClick={scrollToTop} href='/'>
								Naar boven
							</a>
						</li>
						<li>
							<ScrollLink to='repertoire'>
								<button
									aria-label='Navigeer naar mijn repertoire'
									onClick={toggleOpen}
								>
									Repertoire
								</button>
							</ScrollLink>
						</li>
						<li>
							<ScrollLink to='biografie'>
								<button
									aria-label='Navigeer naar mijn biografie'
									onClick={toggleOpen}
								>
									Biografie
								</button>
							</ScrollLink>
						</li>
						<li>
							<ScrollLink to='contact'>
								<button
									aria-label='Navigeer naar mijn contactinformatie'
									onClick={toggleOpen}
								>
									Contact
								</button>
							</ScrollLink>
						</li>
					</ul>
				</div>
			</CSSTransition>
		</div>
	)
}

export default MobileMenu
