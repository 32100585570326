import React from 'react'

import { Link } from 'react-scroll'

const ScrollLink = ({ to, children }) => {
	return (
		<Link to={to} spy={true} hashSpy={true} smooth={true} duration={350}>
			{children}
		</Link>
	)
}

export default ScrollLink
