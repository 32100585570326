import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import BandcampEmbed from './BandcampEmbed'

const BandcampEmbeds = ({ data }) => {
	const isMobile = useMediaQuery({ query: `(max-width: 767px)` })
	const [isOpen, setOpen] = React.useState(false)
	const buttonRef = React.useRef()

	const albums = data.nodes.reduce((map, node) => {
		const album =
			'data' in node
				? node.data['Bandcamp'].split('album=')[1].split(' ')[0]
				: ''
		map.push(album)
		return map
	}, [])

	return (
		<div className={'embeds'}>
			{albums.slice(0, 3).map((album, index) => (
				<BandcampEmbed album={album} key={index} />
			))}
			{isMobile ? (
				<SlideDown className={'my-dropdown-slidedown'}>
					{isOpen ? (
						<div className={'more-embeds'}>
							{albums.slice(3, albums.length).map((album, index) => (
								<BandcampEmbed album={album} key={index} />
							))}
						</div>
					) : (
						<div ref={buttonRef} className={'show-more'}>
							<button
								aria-label='Bekijk meer demo-opnames'
								onClick={() => {
									setOpen(true)
									console.log(isOpen)
								}}
							>
								bekijk meer
							</button>
						</div>
					)}
				</SlideDown>
			) : (
				<div className={'more-embeds'}>
					{albums.slice(3, albums.length).map((album, index) => (
						<BandcampEmbed album={album} key={index} />
					))}
				</div>
			)}
		</div>
	)
}

export default BandcampEmbeds
