import React from 'react'

const BandcampEmbed = ({ album }) => {
	return (
		<div className={'bandcamp-embed'}>
			<iframe
				title={`Audioplayer voor Marianne Ottevanger's demos`}
				src={`https://bandcamp.com/EmbeddedPlayer/album=${album}/size=small/bgcol=ffffff/linkcol=333333/artwork=none/transparent=true/`}
				style={{
					width: '100%',
					height: '2.75rem',
					border: 0,
				}}
				allowfullscreen
				allow='encrypted-media;'
			></iframe>
		</div>
	)
}

export default BandcampEmbed
