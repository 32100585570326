import * as React from 'react'
import { Helmet } from 'react-helmet'

import { Element, animateScroll as scroll } from 'react-scroll'
import ScrollLink from './ScrollLink'

import ReactMarkdown from 'react-markdown'

import '../styles/main.sass'

import landingImage from '../images/portrait.jpg'
import landingBlurredImage from '../images/portrait-blurred.jpg'
import introductionImage from '../images/introduction.jpg'
import introductionBlurredImage from '../images/introduction-blurred.jpg'
import landscapeImage from '../images/contact-landscape.jpg'
import mediaCardImage from '../images/media-card.jpg'
import logoImage from '../images/logo.png'

import ProgressiveImage from './ProgressiveImage'
import BandcampEmbeds from './BandcampEmbeds'
import MobileMenu from './MobileMenu'

const App = ({ data }) => {
	const content = data.content.nodes.reduce((map, node) => {
		map[node.data['ID']] = node.data['Tekst']
		return map
	}, {})

	const links = data.links.nodes.reduce((map, node) => {
		map[node.data['Platform']] = node.data['URL']
		return map
	}, {})

	const demo = data.demos.nodes[0]
	const album =
		'data' in demo ? demo.data['Bandcamp'].split('album=')[1].split(' ')[0] : ''

	const videos = data.videos.nodes.reduce((map, node) => {
		let videoId = ''

		if ('data' in node) {
			const matches = node.data['URL'].match(
				//eslint-disable-next-line
				/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i
			)

			if (matches.length >= 2) videoId = matches[1]
		}

		map.push(videoId)
		return map
	}, [])

	const scrollToTop = (event) => {
		event.preventDefault()
		scroll.scrollToTop()
	}

	return (
		<main>
			<Helmet
				htmlAttributes={{
					lang: 'nl',
				}}
			>
				<title>Marianne Ottevanger - Zangeres en pianiste</title>
				<meta
					name='keywords'
					content='marianne ottevanger, zangeres, pianiste, epe, muziek'
				/>
				<meta
					name='description'
					content='Zangeres en pianiste. Van jongs af aan is muziek mijn passie. Ik wil met mijn muziek graag iets toevoegen aan bijzondere momenten in jouw leven.'
				/>
				<link rel='canonical' href='https://www.marianneottevanger.com/' />
				<meta name='twitter:card' content='summary' />
				<meta
					name='twitter:title'
					content='Marianne Ottevanger - Zangeres en pianiste'
				/>
				<meta
					name='twitter:description'
					content='Zangeres en pianiste. Van jongs af aan is muziek mijn passie. Ik wil met mijn muziek graag iets toevoegen aan bijzondere momenten in jouw leven.'
				/>
				<meta property='twitter:image' content={mediaCardImage} />
				<meta property='og:title' content='Kunyit' />
				<meta
					property='og:site_name'
					content='Marianne Ottevanger - Zangeres en pianiste'
				/>
				<meta property='og:url' content='https://www.marianneottevanger.com/' />
				<meta
					property='og:description'
					content='Zangeres en pianiste. Van jongs af aan is muziek mijn passie. Ik wil met mijn muziek graag iets toevoegen aan bijzondere momenten in jouw leven.'
				/>
				<meta property='og:type' content='business.business' />
				<meta property='og:locale' content='nl_NL' />
				<meta property='og:image' content={mediaCardImage} />
			</Helmet>
			<MobileMenu />
			<div className={'block block-landing'}>
				<div className={'block-content'}>
					<div className={'subcontent'}>
						<ul className={'menu'}>
							<li>Home</li>
							<li>
								<ScrollLink to='repertoire'>Repertoire</ScrollLink>
							</li>
							<li>
								<ScrollLink to='biografie'>Biografie</ScrollLink>
							</li>
							<li>
								<ScrollLink to='contact'>Contact</ScrollLink>
							</li>
						</ul>
						<h1>Marianne Ottevanger</h1>
						<h3>Zangeres en pianiste</h3>
						<p>{content.landing}</p>
						<div className={'demo-container'}>
							<div className={'box'}></div>
							<div className={'demo'}>
								<iframe
									title='Afspeler demo van Marianne Ottevanger'
									src={`https://bandcamp.com/EmbeddedPlayer/album=${album}/size=large/bgcol=fcf9f4/linkcol=131a20/tracklist=false/artwork=none/transparent=true/`}
									style={{
										width: '100%',
										height: '100%',
										border: 0,
									}}
									allowfullscreen
									allow='encrypted-media;'
								></iframe>
							</div>
							<span className={'desktop-only'}>
								<ScrollLink to='repertoire'>
									Beluister meer demo opnames
								</ScrollLink>
							</span>
						</div>
					</div>
					<div className={'photo-container'}>
						<div className={'image-container'}>
							<ProgressiveImage
								alt={'Portretfoto van Marianne Ottevanger bij haar vleugel'}
								lowQuality={landingBlurredImage}
								highQuality={landingImage}
							/>
						</div>
					</div>
				</div>
			</div>
			<Element name='repertoire'>
				<div className={'block block-repertoire'}>
					<div className={'block-content'}>
						<h2>Mijn repertoire</h2>
						<div className={'subcontent'}>
							<div className={'left-container'}>
								<BandcampEmbeds data={data.demos} />
								<p className={'green tablet-only'}>
									Wil je meer muziek horen?{' '}
									<ScrollLink to='contact'>Neem contact met me op</ScrollLink>.
								</p>
							</div>
							<div className={'right-container'}>
								<ReactMarkdown>{content.repertoire}</ReactMarkdown>
							</div>
						</div>
						<p className={'green'}>
							Wil je meer muziek horen?{' '}
							<ScrollLink to='contact'>Neem contact met me op</ScrollLink>.
						</p>
					</div>
				</div>
			</Element>
			<Element name='introductie'>
				<div className={'block block-introduction'}>
					<div className={'block-content'}>
						<div className={'subcontent subcontent-text'}>
							<div className={'left-container'}>
								<div className={'image-container'}>
									<ProgressiveImage
										alt={'Portretfoto van Marianne Ottevanger, zittend'}
										lowQuality={introductionBlurredImage}
										highQuality={introductionImage}
									/>
								</div>
							</div>
							<div className={'right-container'}>
								<div className={'image-container floating-image'}>
									<ProgressiveImage
										alt={'Portretfoto van Marianne Ottevanger, zittend'}
										lowQuality={introductionBlurredImage}
										highQuality={introductionImage}
									/>
								</div>
								<ReactMarkdown
									components={{
										a: (props) => (
											<a href={props.href} target='_blank' rel='noreferrer'>
												{props.children}
											</a>
										),
									}}
								>
									{content.introductie}
								</ReactMarkdown>
							</div>
						</div>
						<div className={'subcontent subcontent-videos'}>
							<div className={'left-container'}>
								<div className={'video'}>
									<iframe
										width='564'
										height='317'
										src={`https://www.youtube.com/embed/${videos[0]}`}
										frameBorder='0'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
										title='Embedded youtube video'
									/>
								</div>
							</div>
							<div className={'right-container desktop-only'}>
								<div className={'video'}>
									<iframe
										width='564'
										height='317'
										src={`https://www.youtube.com/embed/${videos[1]}`}
										frameBorder='0'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
										title='Embedded youtube video'
									/>
								</div>
							</div>
						</div>
						<p className={'centered'}>
							Op mijn{' '}
							<a
								target='_blank'
								rel='noreferrer'
								href={links.YouTube}
								className={'with-icon'}
							>
								YouTube-kanaal
							</a>{' '}
							vind je meer filmpjes.
						</p>
					</div>
				</div>
			</Element>
			<div className={'block block-text'}>
				<div className={'block-content'}>
					<div className={'subcontent'}>
						<Element name='biografie'>
							<div className={'left-container'}>
								<div className={'z-index'}>
									<h2>Biografie</h2>
									<ReactMarkdown
										components={{
											a: (props) => (
												<a href={props.href} target='_blank' rel='noreferrer'>
													{props.children}
												</a>
											),
										}}
									>
										{content.biografie}
									</ReactMarkdown>
								</div>
							</div>
						</Element>
						<Element name='contact'>
							<div className={'right-container'}>
								<h2>Contact</h2>
								<p>{content['tekst-boven']}</p>
								<p className={'contact-info email'}>
									<a href={`mailto:${links.Email}`}>
										{(() => {
											const email = links.Email
											const parts = email.split('@')

											if (parts.length === 2) {
												return (
													<span>
														<strong>{parts[0]}</strong>@{parts[1]}
													</span>
												)
											} else return email
										})()}
									</a>
								</p>
								<p className={'contact-info phone'}>
									<a href={`tel:${links.Telefoon}`}>
										{links.Telefoon.replace(/(.{2})/g, '$1 ')}
									</a>
								</p>
								<p>{content['tekst-onder']}</p>
								<p>
									Voor nieuws en info, kijk op mijn Facebook pagina:{' '}
									<a
										target='_blank'
										rel='noreferrer'
										href={links.Facebook}
										className={'with-icon'}
									>
										Marianne Ottevanger
									</a>
								</p>
								<div className={'image-container desktop-only'}>
									<img
										alt='Foto met Marianne Ottevanger in een natuurlandschap'
										src={landscapeImage}
									/>
								</div>
							</div>
						</Element>
					</div>
				</div>
			</div>
			<div className={'block block-quote'}>
				<div className={'block-content'}>
					<ReactMarkdown>{content.quote}</ReactMarkdown>
				</div>
			</div>
			<div className={'block block-photo'}>
				<div className={'block-content'}>
					<div className={'image-container'}>
						<img
							alt='Foto met Marianne Ottevanger in een natuurlandschap'
							src={landscapeImage}
						/>
					</div>
				</div>
			</div>
			<div className={'block block-footer'}>
				<div className={'block-content'}>
					<div className={'subcontent'}>
						<div className={'left-container'}>
							<div className={'logo-container'}>
								<div className={'logo-icon'}>
									<img alt='Logo van Marianne Ottevanger' src={logoImage} />
								</div>
								<div className={'logo-text'}>
									<h1>Marianne Ottevanger</h1>
									<h3>Zangeres en pianiste</h3>

									<p className={'aqua desktop-only'}>
										<a
											target='_blank'
											rel='noreferrer'
											href={links.Facebook}
											className={'with-icon'}
										>
											Facebook
										</a>
										,{' '}
										<a
											target='_blank'
											rel='noreferrer'
											href={links.YouTube}
											className={'with-underline'}
										>
											YouTube
										</a>
										,{' '}
										<a
											target='_blank'
											rel='noreferrer'
											href={links.Bandcamp}
											className={'with-underline'}
										>
											Bandcamp
										</a>
									</p>
								</div>
							</div>
							<p className={'aqua mobile-only'}>
								<a
									target='_blank'
									rel='noreferrer'
									href={links.Facebook}
									className={'with-icon'}
								>
									Facebook
								</a>
								,{' '}
								<a
									target='_blank'
									rel='noreferrer'
									href={links.YouTube}
									className={'with-underline'}
								>
									YouTube
								</a>
								,{' '}
								<a
									target='_blank'
									rel='noreferrer'
									href={links.Bandcamp}
									className={'with-underline'}
								>
									Bandcamp
								</a>
							</p>
						</div>
						<div className={'right-container'}>
							<div className={'credits'}>
								<p>
									<ReactMarkdown
										children={content.credits}
										components={{
											a: (props) => (
												<a href={props.href} target='_blank' rel='noreferrer'>
													{props.children}
												</a>
											),
										}}
									/>
								</p>
							</div>
							<p>
								<a onClick={scrollToTop} href='/' className={'with-underline'}>
									Terug naar boven
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</main>
	)
}

export default App
